"use strict";
exports.__esModule = true;
exports.config = void 0;
var hostname = typeof window === 'undefined'
    ? process.env.BASE_URL || 'localhost' // Server-side
    : process.env.REACT_APP_BASE_URL || 'localhost'; // Client-side
console.log("Hostname = ".concat(hostname));
var ENV_NAME = 'qrcode';
//const domainName = `https://${ENV_NAME}.mmwebapp.net`.toLowerCase()
var ENV = process.env.NODE_ENV || 'development';
var port;
var baseUri;
console.log(ENV);
if (process.env.NODE_ENV === 'production') {
    port = 8080;
    //baseUri = `https://${ENV_NAME}.azurewebsites.net`
    baseUri = "https://".concat(ENV_NAME, ".mmwebapp.net");
    console.log("Deployed to Azure baseUri = ".concat(baseUri));
    console.log("Deployed to Azure NODE_ENV = ".concat(process.env.NODE_ENV));
    console.log("Deployed to Azure PORT = ".concat(port));
}
else {
    port =
        typeof window === 'undefined'
            ? Number(process.env.PORT) || 8080 // Server-side
            : Number(process.env.REACT_APP_PORT) || 8080; // Client-side
    baseUri = "http://localhost:".concat(port);
    console.log("Local development baseUri = ".concat(baseUri));
    console.log("Local NODE_ENV = ".concat(process.env.NODE_ENV));
}
exports.config = {
    baseUrl: baseUri,
    apiBaseUrl: "".concat(baseUri, "/api"),
    port: port,
    environment: ENV
};
exports["default"] = exports.config;
