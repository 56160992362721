// src/utils/api.ts

import axios from 'axios'
import { config, VCardData } from 'shared/dist'

const api = axios.create({
  baseURL: `${config.apiBaseUrl}`,
})

export const getVCards = async (): Promise<VCardData[]> => {
  const response = await api.get<VCardData[]>('/vcards')
  return response.data
}

export const addVCard = async (vCard: VCardData): Promise<VCardData> => {
  const response = await api.post<VCardData>('/vcards', vCard)
  return response.data
}

export const updateVCard = async (vCard: VCardData): Promise<VCardData> => {
  const response = await api.put<VCardData>(`/vcards/${vCard.id}`, vCard)
  return response.data
}

export const deleteVCard = async (id: string): Promise<void> => {
  await api.delete(`/vcards/${id}`)
}

export const downloadQRCodes = async (): Promise<Blob> => {
  const response = await api.get('/download-qrcodes', {
    responseType: 'blob',
  })
  return response.data
}

export const uploadFile = async (file: FormData): Promise<VCardData[]> => {
  const response = await api.post<VCardData[]>('/upload', file)
  return response.data
}

export const getScanCounts = async (): Promise<{ [id: string]: number }> => {
  const response = await api.get<{ [id: string]: number }>('/scan-counts')
  return response.data
}

export type { VCardData }
