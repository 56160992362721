import React, { useState, useEffect } from 'react'
import MainLayout from './components/MainLayout'
import VCardForm from './components/VCardForm'
import VCardTable from './components/VCardTable'
import FileUpload from './components/FileUpload'
import DownloadButton from './components/DownloadButton'
import { getVCards, VCardData, getScanCounts } from './utils/api'
import { Tabs, Tab, Box, Typography, Paper, Divider } from '@mui/material'

const App: React.FC = () => {
  const [vCards, setVCards] = useState<VCardData[]>([])
  const [vCardToEdit, setVCardToEdit] = useState<VCardData | undefined>(undefined)
  const [hasQRCodes, setHasQRCodes] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [scanCounts, setScanCounts] = useState<{ [id: string]: number }>({})

  const fetchVCards = async () => {
    const fetchedVCards = await getVCards()
    if (Array.isArray(fetchedVCards)) {
      setVCards(fetchedVCards)
      setHasQRCodes(fetchedVCards.some((vCard) => !!vCard.qrCodeUrl))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const cardsData = await getVCards()
      const countsData = await getScanCounts()
      setVCards(cardsData)
      setScanCounts(countsData)
    }
    fetchData()
    fetchVCards()
  }, [])

  const handleAddVCard = (newVCard: VCardData) => {
    setVCards((prevVCards) => {
      const existingIndex = prevVCards.findIndex((vCard) => vCard.id === newVCard.id)
      if (existingIndex !== -1) {
        const updatedVCards = [...prevVCards]
        updatedVCards[existingIndex] = newVCard
        return updatedVCards
      }
      return [...prevVCards, { ...newVCard, id: newVCard.id || Math.random().toString(36).substring(2, 15) }]
    })
    setHasQRCodes(true)
  }

  const handleEditVCard = (vCard: VCardData) => {
    setVCardToEdit(vCard)
  }

  const handleDeleteVCard = (id: string) => {
    setVCards((prevVCards) => {
      const updatedVCards = prevVCards.filter((vCard) => vCard.id !== id)
      setHasQRCodes(updatedVCards.some((vCard) => !!vCard.qrCodeUrl))
      return updatedVCards
    })
  }

  const handleUploadSuccess = (newVCards: VCardData[]) => {
    setVCards((prevVCards) => [...prevVCards, ...newVCards])
    setHasQRCodes(true)
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  return (
    <MainLayout>
      <Paper elevation={3} sx={{ p: 3, mx: 'auto', mb: 4 }}>
        <Typography variant='h5' gutterBottom>
          Generate VCard QR Code
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <Box sx={{ width: '100%' }}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label='vCard and Excel upload tabs'>
            <Tab label='vCard Form' />
            <Tab label='Excel Upload' />
          </Tabs>
          {selectedTab === 0 && (
            <Box sx={{ p: 0 }}>
              <VCardForm onAddVCard={handleAddVCard} vCardToEdit={vCardToEdit} />
            </Box>
          )}
          {selectedTab === 1 && (
            <Box sx={{ p: 0 }}>
              <FileUpload onUploadSuccess={handleUploadSuccess} />
            </Box>
          )}
        </Box>
      </Paper>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'center' }} gap={2}>
        <Box sx={{ width: '100%' }} textAlign={'right'}>
          <DownloadButton hasQRCodes={hasQRCodes} />
        </Box>
        <VCardTable vCards={vCards} onEdit={handleEditVCard} onDelete={handleDeleteVCard} scanCounts={scanCounts} />
      </Box>
    </MainLayout>
  )
}

export default App
